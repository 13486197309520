<template>
  <div></div>
</template>

<script>
import { useApi } from "../api/guard";

import axios from "axios";
import guard from "./guard";

export const EnumKind = {
  LOGISTICA_REVERSA: "Logística Reversa",
  ENTREGA_EM_LOTE: "Entrega em Lote",
};

export default {
  name: "via-orders-api",

  mixins: [guard],

  data() {
    return {
      api: useApi(),
    };
  },

  methods: {
    getFilterForOrdersApi() {
      let filter = {
        id: null,
        lot_id: null,
        lastPage: null,
        perPage: null,
        sortBy: null,
        search: null,
        search_kind: null,
        kind: null,
        status: null,
        search_scheduled: null,
        showInactiveRecords: null,
        account_id: null,
        transporter_account_id: null,
        integration_id: null,
        integration_type: null,
        billing_date_to_receive: null,
        channel: null,
        transporter_id: null,
        from_report: null,
        company_kind: null,
        period_start: null,
        period_end: null,
        period_kind: null,
        areas: null,
        situation: null,
        federal_id: null,
        calendar_view: null,
        scheduling_status: null,
        kind_ctrl: null,
        sla_veredito: null,
        sla_min: null,
        sla_max: null,
        sla_now: null,
        advancedFilters: [],
        list_by: null,
        list_items: null,
        sent_to_scheduling: null,
        status_list: [],
        status_return_list: [],
        status_return_out_list: [],
        account_ids: [],
        transporter_account_ids: [],
        transporter_ids: [],
        its_phantom: false,
        xlsx_is_resumed: null,
        is_invalid_address: null,
      };
      return filter;
    },

    async getOrderApi(id, sla_now = false) {
      let filter = this.getFilterForOrdersApi();
      filter.id = id;
      filter.showInactiveRecords = true;
      filter.sla_now = sla_now;
      let result = await this.getOrdersApi(filter);
      if (result != null) return result.orders[0];
    },

    async getShareOrders(params) {
      try {
        const response = await axios({
          method: "get",
          url: `${process.env.VUE_APP_PYTHON_API}/api/v1/order_share/orders`,
          params,
        });
        return response.data;
      } catch (error) {
        this.$notify({
          title: "Erro ao consultar pedidos por documento.",
          text: error,
          type: "error",
        });
      }
    },

    async shareOrder(orderId) {
      try {
        const response = await axios({
          method: "patch",
          url: `${process.env.VUE_APP_PYTHON_API}/api/v1/orders/share/${orderId}`,
          headers: {
            ...this.getBearer(),
          },
        });
        return response.data;
      } catch (error) {
        this.$notify({
          title: "Erro ao compartilhar pedido.",
          text: error,
          type: "error",
        });
      }
    },

    async getOrderByToken(token) {
      try {
        const response = await axios.get(
          `${process.env.VUE_APP_PYTHON_API}/api/v1/order_share/order`,
          { headers: { "order-hash": token } }
        );
        return response.data;
      } catch (error) {
        this.$notify({
          title: "Erro ao buscar pedido.",
          text: error,
          type: "error",
        });
      }
    },

    async saveOrderApi(order) {
      try {
        let response = await this.api.post(`/api/v1/orders/save`, order);
        return response;
      } catch (error) {
        let detail = error?.response?.data?.detail;
        detail = detail ? detail : error;
        this.$notify({ title: "Erro ao Salvar.", text: detail, type: "error" });
      }
    },

    async updateOrderApi(id, order, forceAssign = false) {
      let response = {};
      try {
        response = await axios.put(
          `${process.env.VUE_APP_PYTHON_API}/api/v1/orders/save`,
          { order: order, force_assign: forceAssign },
          {
            params: {
              id: id,
            },
            ...this.getToken(),
          }
        );
        return response;
      } catch (error) {
        this.$notify({
          title: "Erro ao atualizar.",
          text: error,
          type: "error",
        });
      }
    },

    async removeTransporter(orderId) {
      let response = {};
      try {
        response = await axios.get(
          `${process.env.VUE_APP_PYTHON_API}/api/v1/orders/remove-transporter/${orderId}`,
          {
            ...this.getToken(),
          }
        );
        return response;
      } catch (error) {
        this.$notify({
          title: "Erro ao cancelar atribuição de pedido.",
          text: error,
          type: "error",
        });
      }
    },

    async getOrdersApi(filter, export_xls = false) {
      try {
        let getValue = (item) => {
          return item.value;
        };

        let response = await this.api({
          method: "post",
          url: `${process.env.VUE_APP_PYTHON_API}/api/v1/orders/crud`,
          headers: {
            ...this.getBearer(),
          },
          params: {
            id: filter.id,
            company_id: filter.company_id,
            account_id: filter.account_id,
            transporter_account_id: filter.transporter_account_id,
            transporter_id: filter.transporter_id,
            federal_id: filter.federal_id,
            lot_id: filter.lot_id,
            page: filter.lastPage,
            itens_per_page: filter.perPage,
            xlsx_is_resumed: filter.xlsx_is_resumed,
            sort_by: filter.sortBy,
            search: filter.search,
            search_kind: filter.search_kind,
            kind: filter.kind,
            status: filter.status,
            search_scheduled: filter.search_scheduled,
            from_report: filter.from_report,
            company_kind: filter.company_kind,
            period_start: filter.period_start,
            period_end: filter.period_end,
            period_kind: filter.period_kind,
            situation: filter.situation,
            scheduling_status: filter.scheduling_status,
            kind_ctrl: filter.kind_ctrl,
            sent_to_scheduling: filter.sent_to_scheduling,
            integration_id: filter.integration_id,
            integration_type: filter.integration_type,
            billing_date_to_receive: filter.billing_date_to_receive,
            channel: filter.channel,
            export_xls: export_xls,
            show_inactive_records: filter.showInactiveRecords,
            sla_veredito: filter.sla_veredito,
            calendar_view: filter.calendar_view,
            sla_min: filter.sla_min || null,
            sla_max: filter.sla_max || null,
            sla_now: filter.sla_now,
            list_by: filter.list_by,
            list_items: filter.list_items,
            route_id: filter.routeId,
            voluntary: filter.voluntary,
            fiscal_document_number: filter.fiscalDocumentNumber,
            fiscal_document_key: filter.fiscalDocumentKey,
            its_phantom: filter.its_phantom,
            is_invalid_address: filter.is_invalid_address,
          },
          data: {
            kinds: filter.kinds,
            areas: filter.areas ? filter.areas : [],
            accounts: {
              account_ids: filter.account_ids || filter.accountIds,
              transporter_account_ids: filter.transporter_account_ids,
              transporter_ids: filter.transporter_ids,
            },
            statuses: {
              status_list: filter.status_list
                ? filter.status_list.map(getValue)
                : null,
              status_return_list: filter.status_return_list
                ? filter.status_return_list.map(getValue)
                : null,
              status_return_out_list: filter.status_return_out_list
                ? filter.status_return_out_list.map(getValue)
                : null,
            },
            advanced_filters: filter.advancedFilters,
          },
        });
        if (response != null) return response.data;
      } catch (error) {
        this.$notify({
          title: "Erro ao atualizar.",
          text: error.response.data.detail,
          type: "error",
        });
      }
    },

    async getOrdersForMap(params) {
      try {
        const response = this.api.get(`/api/v1/reports/google_maps`, {
          params,
        });
        return response;
      } catch (error) {
        this.$notify({
          title: "Erro ao Atualizar.",
          text: error,
          type: "error",
        });
      }
    },

    async getOrdersTotalsPerOperations(params) {
      try {
        const response = this.api.get(`/api/v1/reports/orders_per_operations`, {
          params,
        });
        return response;
      } catch (error) {
        this.$notify({
          title: "Erro ao Atualizar.",
          text: error,
          type: "error",
        });
      }
    },
  },
};
</script>
